<template>
  <div class="material__need__wrapper">
    <div class="item_title">
      <div class="item_titleline"></div>
      方案征集管理
    </div>
    <div class="content">
      <div>
        <a-row>
          <a-col :span="8">
            <a-row>
              <a-col :span="6">
                <div class="msgRecruitUse_text">需求发布时间</div>
              </a-col>
              <a-col :span="18">
                <a-date-picker
                  style="width: 220px"
                  v-model="createTime"
                  value-format="YYYY-MM-DD "
                  placeholder="请输入需求发布时间"
                />
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row>
              <a-col :span="6">
                <div class="msgRecruitUse_text">方案标题</div>
              </a-col>
              <a-col :span="18">
                <a-input placeholder="请输入方案标题" style="width: 220px" allowClear v-model="title" />
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="8" style="text-align: right" v-perms="'search'">
            <a-button type="" @click="handleReset">重置</a-button>
            <a-button type="primary" @click="$refs.table.initPage()">查询</a-button>
          </a-col>
        </a-row>
      </div>
      <div style="margin-top: 24px">
        <p-table
          row-key="id"
          :columns="columns"
          ref="table"
          :source-data="handleGetTable"
          :scorll="{ y: 600 }"
          extraHeight="400"
          download
          fileName="资料需求管理"
        >
          <template slot="headerLeft">
            <a-button type="primary" @click="handleOpenPage" v-perms="'add'">方案征集</a-button>
          </template>
          <template slot="status" slot-scope="text, record">
            <span>
              {{ statusDict(text) }}
              <a-statistic-countdown
                style="display: inline-block;"
                v-if="record.status == '3' || record.status == '4' || record.status == '5'"
                :value="deadline(record.statusUpdateTime)"
                format="D 天 H 时 m 分 s 秒"
                @finish="$refs.table.refresh()"
              />
            </span>
          </template>
          <template slot="operation" slot-scope="text, record">
            <div class="operation_btn" @click="viewDetails(record)" v-perms="'details'">查看详情</div>
            <div v-if="record.status == '0'" class="operation_btn" @click="handleWeChatPay(record.id, record.orderNo)" v-perms="'pay'">
              立即付款
            </div>
            <a-popconfirm
              placement="top"
              ok-text="是"
              cancel-text="否"
              title="确认已经接收了吗？"
              @confirm="updataStuate(record, '5')"
              v-perms="'confirm'"
            >
              <div class="operation_btn" v-if="record.status == '3'">确认接收</div>
            </a-popconfirm>
            <div
              v-if="record.status == '4' || record.status == '5'"
              class="operation_btn"
              @click="manualInterventionClick(record)"
              v-perms="'sqrgjr'"
            >
              申请人工介入
            </div>
            <div v-if="record.status == '8'" class="operation_btn" @click="resultHandlingClick(record)" v-perms="'look'">
              查看处理结果
            </div>
            <a-popconfirm
              placement="top"
              v-if="record.status == '1'"
              ok-text="是"
              cancel-text="否"
              title="确认取消发布吗？"
              @confirm="updataStuate(record, '10')"
              v-perms="'cancel'"
            >
              <div class="operation_btn">取消发布</div>
            </a-popconfirm>
          </template>
        </p-table>
      </div>
    </div>
    <!-- 二维码付款 -->
    <payZf ref="payZf" />
    <!-- 查看详情 -->
    <Details ref="Details" />
    <!-- 人工介入 -->
    <manualIntervention ref="manualIntervention" />
    <!-- 人工处理结果 -->
    <resultHandling ref="resultHandling" />
    <a-modal v-model="visible" title="取消发布提醒" @ok="visible = false">
      <p>当前资料需求已取消发布，付款金额将在1-3个工作日返还至原账户！</p>
    </a-modal>
  </div>
</template>

<script>
import { getTypeDetail } from '@/api/materialShop'

import {
  EnterpriseMaterialfindRelease,
  EnterpriseMaterialupdateStatus,
  EnterpriseMaterialDemateControllerviewDetails
} from '@/api/msg'
import moment from 'moment'
import payZf from './zf.vue'
import Details from './details.vue'
import manualIntervention from './manualIntervention.vue'
import resultHandling from './resultHandling.vue'

function formatTime (t) {
  return t ? moment(t).format('YYYY-MM-DD') : t
}
const columns = [
  {
    title: '序号',
    dataIndex: 'key',
    width: 60,
    customRender: (t, r, index) => index + 1
  },
  {
    title: '方案标题',
    download: true,
    dataIndex: 'title'
  },
  {
    title: '需求发布人',
    download: true,
    dataIndex: 'createBy'
  },

  {
    title: '需求发布时间',
    download: true,
    dataIndex: 'createTime',
    customRender: (t) => formatTime(t),
    sorter: true
  },
  {
    title: '需求响应截止时间',
    download: true,
    dataIndex: 'endTime',
    customRender: (t) => formatTime(t),
    sorter: true
  },
  // {
  //   title: '是否选择资料提供人',
  //   download: true,
  //   dataIndex: 'isElect',
  //   customRender: text => (text == 0 ? '否' : '是')
  // },
  {
    title: '状态',
    download: true,
    dataIndex: 'status',
    width: 300,
    scopedSlots: { customRender: 'status' }
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' }
  }
]

export default {
  name: 'Index',
  data () {
    return {
      visible: false,
      dict: [],
      loading: false,
      value: 5,
      autoExpandParent: true,
      tableData: [],
      columns,
      createTime: '',
      title: '',
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        hideOnSinglePage: true,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total) => `总条数：${total}`
      }
    }
  },
  components: {
    // 付款
    payZf,
    // 详情
    Details,
    // 申请人工介入
    manualIntervention,
    // 人工处理结果
    resultHandling
  },
  created () {
    getTypeDetail({ dictName: 'material_demand_status', pageSize: 1000 }).then((res) => {
      res.data.map((res) => {
        this.dict.push({ label: res.label, value: res.value })
      })
    })
  },
  methods: {
    handleOpenPage () {
      window.open('/material/msgDemand')
    },
    deadline (statusUpdateTime) {
      return statusUpdateTime + 1000 * 60 * 60 * 24 * 3
    },
    refresh () {
      this.$refs.table.refresh()
    },
    statusDict (val) {
      return this.dict.find((res) => res.value === val)?.label
    },
    resultHandlingClick (val) {
      this.$nextTick(() => {
        this.$refs.resultHandling.detail(val)
      })
    },
    manualInterventionClick (val) {
      this.$nextTick(() => {
        this.$refs.manualIntervention.detail(val)
      })
    },
    viewDetails (val) {
      EnterpriseMaterialDemateControllerviewDetails(val).then((res) => {
        console.log(this.$refs.Details)
        this.$nextTick(() => {
          this.$refs.Details.detail(res.data)
        })
      })
    },
    handleWeChatPay (id, outTradeNo) {
      const that = this
      this.$nextTick(() => {
        that.$refs.payZf.handleWeChatPay(id, outTradeNo)
      })
    },
    updataStuate (record, val) {
      EnterpriseMaterialupdateStatus({
        id: record.id,
        orderNo: record.orderNo,
        status: val
      }).then((res) => {
        if (res.success) {
          this.$refs.table.refresh()
          if (val === '10') {
            this.visible = true
          } else {
            this.$message.success('操作成功')
          }
        }
      })
    },
    handleGetQuery () {
      const { title, createTime } = this
      return {
        createTime,
        title
      }
    },
    handleGetTable (params) {
      return EnterpriseMaterialfindRelease({
        ...params,
        ...this.handleGetQuery()
      })
    },
    handleReset () {
      this.createTime = undefined
      this.title = ''
    }
  }
}
</script>

<style lang="less">
@import url('../../../common/style');
@import url('../../recruitUse/recruit/index');
@import url('../../recruitUse/evaluate/index');
@import url('../msgRecruitUse/index');
.material__need__wrapper {
  height: 100%;
  .content {
    height: calc(100% - 45px);
    padding: 24px;
  }
}
.div_zf {
  cursor: pointer;
  width: 220px;
  height: 100px;
  border: 1px solid black;
  display: inline-block;
  position: relative;
  .div_zf_img {
    width: 200px;
    height: 70px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
}
.vueQr {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.ant-statistic-content{
  margin-left:10px;
  font-size: 14px;
}
</style>
